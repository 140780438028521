import React, { useState, useEffect } from 'react'
import Timer from '../../Timer';
import { useLocation, useNavigate } from 'react-router';
import Swal from 'sweetalert2'
import URL from '../../../services/API_URL';
import WritingQuestionPage from './WritingQuestionPage';

const IELTStestPageWriting = ({nextPage}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [soalWriting, setSoalWriting] = useState([])
  const [, setBtnDisabled] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [startTime, setStartTime] = useState(false)
  const [popup, setPopup] = useState(true)
  const [answerState, setAnswerState] = useState([])
  const [page, setPage] = useState({
    activeObject: 1,
    activeFocus: null,
    part:[
        { 
            id: 1,
        },
        {
            id: 2,
        }]
    });

  const submit = () => {
      setBtnDisabled(true)
      let data = {
        type: 'writing',
        answer: answerState
    }   
      URL.API_URL.post ('store-answer', data)
      .then((res) => {
        Swal.fire({
            icon: "success",
            title: "Your Writing Answers Saved",
            showConfirmButton: false,
            timer: 2500,
            willClose: () => {
                setBtnDisabled(false)
                navigate('/thank-you', { replace: true })
            }
        });
    })
    .catch((err) => {
        setBtnDisabled(false)
    })
  }

  useEffect(() => {
    if (location.state == null) {
      navigate('/start-ielts-test')
    } else {
      if (popup) {
        Swal.fire({
            title: "Are you ready?",
            confirmButtonText: "Start",
            allowOutsideClick: false,
            icon: "question",
        }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setPopup(false)
                getSoal()
                setStartTime(true)
            } 
        });
      }       
    }
  }, [])

  const toggleActivePallete = (index) => {
    setPage(prev => ({...prev, activeObject: page.part[index].id}))
  }

  const toggleActivePalleteClass = (index) => {
    if (page.activeObject == page.part[index].id) {
        return 'selected'
    } else {
        return ''
    }
  }

  const getSoal = () => {
    URL.API_URL.get ('free/soal-writing')
    .then((res) => {
        setSoalWriting(res.data)
        // -----------------------
        let data = res.data
        let answer = []
        for (let i = 0; i < data.length; i++) {
            let soal = data[i].soal
            for (let j = 0; j < soal.length; j++) {
                answer.push({id_user: location.state?.id, id_soal: soal[j].id, correct_answer: soal[j].jawaban, user_answer: '', is_correct: false})
            }
        }
        setAnswerState(answer)
    })
    .catch((err) => console.log(err))
  }
  
  return (
    <>
    <header className="bg-blue">
        <h2 className='text-white'>Writing Section</h2>
        <Timer timer={3600} category={'writing'} startTime={startTime} setInputDisabled={setInputDisabled}/>
        <div className="nav-control">
            <button onClick={submit} className='btn btn-success'>Submit</button>
        </div>
    </header>

    <WritingQuestionPage parts={page} answerState={answerState} soal={soalWriting} inputDisabled={inputDisabled} />

    <footer>
      {
      page.part.map((p, i) => {
          let no = i+1
          return (
          <div key={i} className={`question-palette ${toggleActivePalleteClass(i, no)}`} onClick={() => toggleActivePallete(i)}>
              <div style={{color:'grey'}} className='p-1s'>Task {no}</div>
          </div>
          )
      })
      }
    </footer>
    </>
  )
}

export default IELTStestPageWriting
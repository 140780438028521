import React, { useState, useEffect } from 'react'
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import Editor from 'react-simple-wysiwyg';

const WritingQuestionPage = ({ parts, soal, inputDisabled, answerState }) => {
    const [sizes, setSizes] = useState([100, '10%', 'auto']);
    const [isMobile, setIsMobile] = useState(false)
    const [text, setText] = useState(['',''])

    const handleChange = (e, number) => {
        const { value } = e.target
        let index = number - 1
        setText(text.map((item, i) => i === index ? value : item));
        let _answer = [...answerState];
        _answer[index].user_answer = value
        _answer.push()
    }

    const countWords = (text) => {
        return text.trim().split(/\s+/).filter(word => word.length > 0).length;
    };

    const handleResize = () => {
        if (window.innerWidth < 769) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
    })

    return (
        <>
            {
                soal?.map((m, i) => {
                    return (
                        <div key={i} style={{ display: `${parts.activeObject == m.part_soal ? 'block' : 'none'}`, marginBottom: '50px' }}>
                            <div style={{ height: '100vh' }}>
                                <SplitPane
                                    split={isMobile ? 'horizontal' : 'vertical'}
                                    sizes={sizes}
                                    onChange={setSizes}
                                >
                                    <Pane minSize={100}>
                                        <div className={`${isMobile ? 'pane-left-mobile' : 'pane-left'}`}>
                                            <div className={`inner-pane-top`}>
                                                <div dangerouslySetInnerHTML={{ __html: m.text_direction }} />
                                                <div className="img text-center">
                                                    {m.image != null ? (<img src={m.image} style={{ width: '100%' }} alt="" />) : ''}
                                                </div>
                                                <div dangerouslySetInnerHTML={{ __html: m.text }} />
                                            </div>
                                        </div>
                                    </Pane>
                                    <div style={{ overflow: 'auto', height: '100%' }}>
                                        <div className={`${isMobile ? 'inner-pane-mobile' : 'inner-pane'}`}>
                                            {
                                                m.soal.map((d, k) => {
                                                    return (
                                                        <div>
                                                            <Editor
                                                             style={{
                                                                height:'400px'
                                                             }}
                                                             disabled={inputDisabled ? true : false} 
                                                             name={`input${d.id}`} 
                                                             value={text[i]} onChange={e => handleChange(e, d.number)} />
                                                            {/* <textarea value={text[i]} style={{ padding: '10px', width: '100%', borderRadius:'5px' }} className='' onChange={e => handleChange(e, d.number)} disabled={inputDisabled ? true : false} name={`input${d.id}`} rows="20"></textarea> */}
                                                            <div>word count: {countWords(text[i])}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </SplitPane>
                            </div>
                        </div>
                    )
                })
            }
        </>

    )
}

export default WritingQuestionPage